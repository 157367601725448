import semver from "semver";
import MovieRenderer from "../MovieRenderer/MovieRenderer";
import { redrawCircle, redrawRectangle } from "../PropertyUtils";

const PIXI = window.PIXI;
window.hyperGlobals = {};

class Commander {
  constructor(app) {
    this.app = app;
    this.totalSeconds = 0;
    this.commands = [];
    this.renderQueue = [];
    this.loading = false;
    this.processingQueue = false;

    window.ndimetadatareceived = (theXMLMDATA) => {
      //do something with the metadata
      try {
        if (this.ws) {
          this.ws.send(
            JSON.stringify({
              group: window.groupID,
              meta_data: theXMLMDATA,
            })
          );
        }
      } catch (err) {
      } finally {
        return "ACK";
      }
    };
    // /  this.connect();
    this.processLoop();
    this.processRender();
  }

  async processLoop() {
    try {
      if (
        this.commands.length > 0 &&
        this.loading === false &&
        this.app.player &&
        this.app.player.project &&
        !this.app.player.project.loading
      ) {
        let json = this.commands.shift();
        let start = Date.now();
        console.log("processing command", json, start);
        if (json.app && json.data) {
          json.data.app = json.app;
        }
        // if (json.data.scene != "Clock") {
        //console.log(json);
        // }

        let new_loader = PIXI.Loader.shared;

        switch (json.action) {
          case "globals":
            json.data.globals.map((global) => {
              window.hyperGlobals[global.name] = global.value;
            });

            break;
          case "animate":
            await this.triggerAnimation(json.data, new_loader);
            break;
          case "preview":
            await this.preview(json, new_loader);
            break;
          case "reload":
            PIXI.Loader.shared.reset();
            PIXI.Loader.shared.resources = {};
            await this.app.player.project.reload();
            break;
          case "update":
            if (this.app?.player?.project?.scenes) {
              let scene = this.app.player.project.scenes.find(
                (scene) => scene.name === json.data.scene
              );
              if (json.data.app === "gaa") {
                scene =
                  this.app.player.project.scenes.find((s) => {
                    return (
                      s.gaa?.templates?.findIndex(
                        (t) => t.name === json.data.scene
                      ) > -1
                    );
                  }) || scene;
              } else if (json.data.app === "football") {
                scene =
                  this.app.player.project.scenes.find((s) => {
                    return (
                      s.football?.templates?.findIndex(
                        (t) => t.name === json.data.scene
                      ) > -1
                    );
                  }) || scene;
              } else if (json.data.app === "rugby") {
                scene =
                  this.app.player.project.scenes.find((s) => {
                    return (
                      s?.rugby?.templates?.findIndex(
                        (t) => t.name === json.data.scene
                      ) > -1
                    );
                  }) || scene;
              } else if (json.data.app === "basketball") {
                scene =
                  this.app.player.project.scenes.find((s) => {
                    return (
                      s?.basketball?.templates?.findIndex(
                        (t) => t.name === json.data.scene
                      ) > -1
                    );
                  }) || scene;
              } else if (json.data.app === "climbing") {
                scene =
                  this.app.player.project.scenes.find((s) => {
                    return (
                      s?.climbing?.templates?.findIndex(
                        (t) => t.name === json.data.scene
                      ) > -1
                    );
                  }) || scene;
              }
              await this.updateData({ scene, data: json.data, new_loader });
            }
            break;
          case "CLEAR":
            this.clearGraphics();
            break;
          case "CLEAR_PREVIEW":
            if (window.preview) {
              this.clearGraphics();
            }
            break;
          case "RENDER":
            this.renderQueue.push({ ...json, uid: json.uid });
            break;
          default:
            break;
        }
        console.log(
          "finished processing command",
          Date.now(),
          Date.now() - start
        );
      }
    } catch (err) {
      console.log(err);
    }

    setTimeout(() => {
      this.processLoop();
    }, 1);
  }

  disconnect() {
    console.log("engine disconnecting");
    this.reconnect = false;
    if (this.ws) {
      this.ws.close();
    }
  }

  connect(key) {
    this.reconnect = true;
    this.ws = new WebSocket(`${window.commandServerURL}`);
    let timeout;
    this.ws.onerror = (err) => {
      console.log("WS error", err);
      this.ws.close();
    };
    this.ws.onclose = (e) => {
      console.log("ENGINE on close", e);
      if (this.reconnect) {
        clearInterval(this.keep_alive);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          this.connect(key);
        }, 1000);
      }
    };
    this.ws.onopen = () => {
      this.keep_alive = setInterval(() => {
        this.ws.send(
          JSON.stringify({
            keep_alive: Date.now(),
            group: key,
          })
        );
      }, 20000);
      try {
        this.ws.send(
          JSON.stringify({
            group: key,
          })
        );
      } catch (err) {
        console.log(err);
      }
      // setTimeout(() => {
      //   try {
      //     this.ws.send(
      //       JSON.stringify({
      //         group: group,
      //         action: "CONNECTED",
      //         payload: {
      //           ip: "192.168.2.113",
      //           name: window.name,
      //           status: "READY",
      //         },
      //       })
      //     );
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }, 10000);
    };
    this.ws.onmessage = (data) => {
      try {
        let json = JSON.parse(data.data);
        if (!json.keep_alive) {
          this.commands.push(json);
        }
      } catch (err) {}
    };
  }

  async preview(json, new_loader) {
    if (
      this.app.player.project &&
      this.app.player.project.scenes &&
      !this.app.player.project.loading
    ) {
      let scenes = [
        this.app.player.project.scenes.find(
          (scene) => scene.name === json.data.scene
        ),
      ];
      if (json.data.app === "gaa") {
        let gaa_scenes = this.app.player.project.scenes.filter((s) => {
          return (
            s.gaa?.templates?.findIndex((t) => t.name === json.data.scene) > -1
          );
        });

        scenes = gaa_scenes || scenes;
      } else if (json.data.app === "football") {
        let football_scene = this.app.player.project.scenes.filter((s) => {
          return (
            s.football?.templates?.findIndex(
              (t) => t.name === json.data.scene
            ) > -1
          );
        });
        scenes = football_scene || scenes;
      } else if (json.data.app === "rugby") {
        scenes =
          [
            this.app.player.project.scenes.find((s) => {
              return (
                s?.rugby?.templates?.findIndex(
                  (t) => t.name === json.data.scene
                ) > -1
              );
            }),
          ] || scenes;
      } else if (json.data.app === "basketball") {
        scenes =
          [
            this.app.player.project.scenes.find((s) => {
              return (
                s?.basketball?.templates?.findIndex(
                  (t) => t.name === json.data.scene
                ) > -1
              );
            }),
          ] || scenes;
      } else if (json.data.app === "climbing") {
        scenes =
          [
            this.app.player.project.scenes.find((s) => {
              return (
                s?.climbing?.templates?.findIndex(
                  (t) => t.name === json.data.scene
                ) > -1
              );
            }),
          ] || scenes;
      }

      if (scenes?.length > 0) {
        // let newer = semver.gt(
        //   this.app.player.project.engine_version,
        //   "2023.1.1"
        // );
        // let length = newer ? scenes.length : 1;
        //versioning not yet implementd
        let length = scenes.length;

        for (let i = 0; i < length; i++) {
          let scene = scenes[i];
          if (scene.loaded === false) {
            scene.loaded = true;
            fetch(
              `${window.projectServerURL}project/${this.app.player.project.name}`
            )
              .then((response) => response.json())
              .then(async (data) => {
                let s = data.find((s) => s.name === json.data.scene);

                scene.load(s);
                //await this.updateDataPreload(json.data);
                if (window.preview) {
                  this.updateData({ scene, data: json.data, new_loader });
                }

                PIXI.Loader.shared.load(() => {
                  this.triggerPreview({ scene, data: json.data });
                });
              })
              .catch((error) => console.error(error));
          } else {
            // PIXI.Loader.shared.reset();
            this.loading = true;
            await this.updateDataPreload({
              scene,
              data: json.data,
              new_loader,
            });

            new_loader.onError.add((err) => {
              console.log("error loading image", err);
            });

            new_loader.load(() => {
              this.loading = false;
              if (window.preview) {
                this.updateData({ scene, data: json.data, new_loader });

                this.triggerPreview({ scene, data: json.data });
              }
            });
          }
        }
      }
    }
  }
  async processRender() {
    if (this.processingQueue === false) {
      let item = this.renderQueue.shift();

      if (item) {
        let json = item;
        this.processingQueue = true;

        if (
          this.app.player.project &&
          this.app.player.project.scenes &&
          json.data?.scene
        ) {
          let scene = this.app.player.project.scenes.find(
            (scene) => scene.name === json.data.scene
          );
          if (scene.loaded === false) {
            fetch(
              `${window.projectServerURL}project/${this.app.player.project.name}`
            )
              .then((response) => response.json())
              .then(async (data) => {
                let s = data.find((s) => s.name === json.data.scene);
                scene.load(s);
                scene.preload();
                if (window.beamcoder && item) {
                  await this.updateDataPreload(item);

                  //PIXI.Loader.shared.load(() => {
                  console.log("LOADED ASSETS");

                  await this.updateData(item);

                  let render = new MovieRenderer({
                    fileName: json.data.filename,
                    uid: item.uid,
                    app: this.app,
                  });
                  //this.app.player.renderer.plugins.prepare.upload(
                  // scene.scene,
                  //  () => {

                  render.render({
                    scene: item.data.scene,
                    timelines: item.data.timelines,
                    ws: this.ws,
                  });
                  try {
                    this.ws.send(
                      JSON.stringify({
                        action: "READY",
                        payload: {
                          ip: "192.168.2.113",
                          name: window.name,
                          status: "READY",
                        },
                      })
                    );
                  } catch (err) {
                    console.log(err);
                  }

                  //  });
                  // });
                }
              })
              .catch((error) => console.error(error));
          } else {
            if (window.beamcoder && item) {
              await this.updateDataPreload(item.data);
              await PIXI.Loader.shared.load(async () => {
                console.log("LOADED ASSETS");
                this.updateData(item.data);
                scene.preload();
                console.log("JSON--------", json);
                let render = new MovieRenderer({
                  fileName: json.filename,
                  uid: item.uid,
                  app: this.app,
                });
                // this.app.player.renderer.plugins.prepare.upload(scene.scene, () => {

                await render.render({
                  scene: item.data.scene,
                  timelines: item.data.timelines,
                  ws: this.ws,
                });
                try {
                  this.ws.send(
                    JSON.stringify({
                      action: "READY",
                      payload: {
                        ip: "192.168.2.113",
                        name: window.name,
                        status: "READY",
                      },
                    })
                  );
                } catch (err) {
                  console.log(err);
                }
                this.processingQueue = false;
              });

              // });
            }
          }
        } else {
          this.processingQueue = false;
          // this.pixi.animate();
        }
      }
    }
    setTimeout(() => {
      this.processRender();
    }, 500);
  }

  async getThumbnail(json) {
    if (this.app.player.project) {
      this.updateDataPreload(json.data);
      this.updateData(json.data);

      this.app.player.project.scenes.map((scene) => {
        scene.scene.visible = false;
      });
      let scene = this.app.player.project.scenes.find(
        (scene) => scene.name === json.data.scene
      );

      scene.scene.visible = true;
      scene.preload();
      let currentTime = 0;

      let timeline = scene.timelines.find((tl) => {
        return tl.name == json.data.timeline;
      });

      for (let i = 0; i < json.data.frame; i++) {
        await timeline.seek(i);
      }
      this.app.player.stage.position.y = 1080 / 1;
      this.app.player.stage.scale.y = -1;
      this.app.player.renderer.render(this.app.player.stage);

      let previewPixels = new Uint8Array(1920 * 1080 * 4);
      window.gl.readPixels(
        0,
        0,
        1920,
        1080,
        window.gl.RGBA,
        window.gl.UNSIGNED_BYTE,
        previewPixels
      );

      this.filt = await window.beamcoder.filterer({
        // Create a filterer for video
        filterType: "video",
        inputParams: [
          {
            width: 1920,
            height: 1080,
            pixelFormat: "rgba",
            timeBase: [1, 25],
            pixelAspect: [1, 1],
          },
        ],
        outputParams: [{ pixelFormat: "rgba" }],
        filterSpec: "format=pix_fmts=rgba,scale=480:270",
      });
      let jpegResult = {};
      let filtFrame = {};
      let frame = window.beamcoder
        .frame({
          width: 1920,
          height: 1080,
          format: "rgba",
        })
        .alloc();

      frame.data = [previewPixels];
      frame.pts = 1 * 25;
      let filtResult = await this.filt.filter([frame]);
      let bytes = filtResult[0].frames[0].data[0];

      this.ws.send(new Uint8Array(bytes, 2073600).buffer);
    }
  }

  start() {
    setTimeout(() => {
      this.animateIn();
      setTimeout(() => {
        this.transition();
        setTimeout(() => {
          this.animteOut();
        }, 2000);
      }, 2000);
    }, 1000);
  }

  animateIn() {
    this.triggerAnimation({
      scene: "Line Up",
      timeline: "IN",
      data: [],
    });
  }

  transition() {
    this.triggerAnimation({
      scene: "Line Up",
      timeline: "T3",
      data: [],
    });
  }

  animteOut() {
    this.triggerAnimation({
      scene: "Line Up",
      timeline: "OUT",
      data: [],
    });
  }

  updateData({ scene, data, new_loader }) {
    try {
      data = JSON.parse(JSON.stringify(data));
      //console.log(data);
      if (this.app.player.project) {
        if (data.data && scene) {
          if (
            data.app !== "football" &&
            data.app !== "rugby" &&
            data.app !== "basketball" &&
            data.app !== "gaa" &&
            data.app !== "climbing"
          ) {
            data.data.forEach((item) => {
              try {
                let obj =
                  item.uuid ||
                  scene.getItemByUUID(item.uuid, scene.scene.children) ||
                  scene.getItemByName(item.name, scene.scene.children);
                if (Array.isArray(item.value)) {
                  for (let i = 0; i < obj.children.length; i++) {
                    if (item.value[i].value && obj.children[i]) {
                      this.updateProperty(
                        obj.children[i],
                        item.value[i].value,
                        new_loader
                      );
                    }
                    for (let x = 0; x < item.value[i].data.length; x++) {
                      let d = item.value[i].data[x];
                      let node = scene.getItemByName(
                        d.name,
                        obj.children[i].children
                      );
                      if (node) {
                        this.updateProperty(node, d.value, new_loader);
                      }
                    }
                  }
                } else {
                  if (obj) {
                    this.updateProperty(obj, item.value, new_loader);
                  }
                }
              } catch (e) {
                console.log(item);
                console.log(e);
              }
            });
          } else {
            let control_objects = scene.getControls({
              children: scene.scene.children,
            });
            control_objects.forEach((obj) => {
              let variable;
              if (data.app === "rugby") {
                variable = obj.control?.data?.rugby?.variables?.filter(
                  (c) => c.template === data.scene
                );
              } else if (data.app === "gaa") {
                variable = obj.control?.data?.gaa?.variables?.filter(
                  (c) => c.template === data.scene
                );
              } else {
                variable = obj.control?.data?.variables?.filter(
                  (c) => c.template === data.scene
                );
              }

              if (variable && variable[0] && variable[0]?.default) {
                this.updateProperty(
                  obj,
                  { text: variable[0]?.default },
                  new_loader
                );
              }
              if (
                data?.timeline !== "OUT" &&
                variable?.[0]?.hide !== "undefined"
              ) {
                this.updateProperty(
                  obj,
                  { visible: !variable?.[0]?.hide },
                  new_loader
                );
              }
            });

            data.data.forEach((item) => {
              let objects = scene.getItemsByDataName({
                name: item.name,
                children: scene.scene.children,
                template: data.scene,
                app: data.app,
              });

              objects.forEach((obj) => {
                this.updateProperty(obj, item.value, new_loader);
              });
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  updateProperty(obj, value, new_loader) {
    for (let i = 0; i < Object.keys(value).length; i++) {
      if (obj.codeRunner && obj.codeRunner.onBeforeValueChange) {
        try {
          obj[Object.keys(value)[i]] =
            obj.codeRunner.onBeforeValueChange({
              property: Object.keys(value)[i],
              old_value: obj[Object.keys(value)[i]],
              new_value: value[Object.keys(value)[i]],
            }) || obj[Object.keys(value)[i]];
        } catch (err) {
          if (window.editor) {
            console.error(obj, err);
          }
        }
      }

      if (obj.type === "CLOCK") {
        if (Object.keys(value)[i] === "action") {
          switch (value[Object.keys(value)[i]]) {
            case "start":
              obj.start(value?.time);
              break;
            case "stop":
              obj.stop(value?.time);
              break;
            case "resume":
              obj.resume();
              break;
            case "reset":
              obj.reset(value?.time);
              break;
            default:
          }
        }
      } else if (Object.keys(value)[i] === "video") {
        let user_location =
          value[Object.keys(value)[i]]?.indexOf("${user}") === 0;

        let path_id =
          this.app.player.project?.use_project_folder && !user_location
            ? this.app.player.project?._id
            : this.app.player.project.user._id;
        let prefix =
          this.app.player.project?.use_project_folder && !user_location
            ? "project/"
            : "";

        let video_src =
          window.projectServerURL +
          "assets/videos/" +
          prefix +
          path_id +
          "/" +
          encodeURIComponent(
            value[Object.keys(value)[i]]?.replace("${user}", "")
          );

        if (new_loader.resources[video_src] === undefined) {
          obj.texture = PIXI.Texture.from(video_src);
          obj._texture.baseTexture.resource.source.muted = true;
          obj._texture.baseTexture.resource.source.autoplay = false;
          obj._texture.baseTexture.resource.source.loop = obj.loop;
        } else {
          new_loader.resources[video_src].data.muted = true;
          obj.texture = PIXI.Texture.from(new_loader.resources[video_src].data);
          obj._texture.baseTexture.resource.source.muted = true;
          obj._texture.baseTexture.resource.source.autoplay = false;
          obj._texture.baseTexture.resource.source.loop = obj.loop;
        }
        obj.src = value[Object.keys(value)[i]];
      } else if (Object.keys(value)[i] === "image") {
        let user_location =
          value[Object.keys(value)[i]]?.indexOf("${user}") === 0;

        let path_id =
          this.app.player.project?.use_project_folder && !user_location
            ? this.app.player.project?._id
            : this.app.player.project.user._id;
        let prefix =
          this.app.player.project?.use_project_folder && !user_location
            ? "project/"
            : "";

        let img =
          window.projectServerURL +
          "assets/images/" +
          prefix +
          path_id +
          "/" +
          encodeURIComponent(
            value[Object.keys(value)[i]]?.replace("${user}", "")
          );

        if (
          new_loader.resources[img] === undefined ||
          !new_loader.resources[img]?.texture
        ) {
          console.log("-----adding to resources-------");
          obj._texture = PIXI.Texture.EMPTY;
          delete new_loader.resources[img];

          new_loader.add(img);
        } else {
          let resource = new_loader.resources[img];
          obj._texture = resource.texture;
        }
        obj.src = value[Object.keys(value)[i]];
        //console.log('before: ', obj)

        obj.width = obj._width;
        obj.height = obj._height;

        // console.log('after: ', obj)
      } else if (Object.keys(value)[i] === "neverno_image") {
        let original_url = value[Object.keys(value)[i]];

        let neverno_img =
          window.projectServerURL +
          "neverno/image/" +
          encodeURIComponent(original_url);
        if (
          new_loader.resources[original_url] === undefined ||
          !new_loader.resources[original_url]?.texture
        ) {
          console.log("-----adding to resources-------");
          obj._texture = PIXI.Texture.EMPTY;
          new_loader.add(original_url, neverno_img);
        } else {
          let resource = new_loader.resources[original_url];
          obj._texture = resource.texture;
        }
        //console.log('before: ', obj)

        obj.width = obj._width;
        obj.height = obj._height;

        // console.log('after: ', obj)
      } else if (
        Object.keys(value)[i] === "colour" &&
        obj.type !== "RECTANGLE" &&
        obj.type !== "CIRCLE" &&
        obj.type !== "IMAGE"
      ) {
        obj.style.fill = value[Object.keys(value)[i]];
      } else if (Object.keys(value)[i] === "colour" && obj.type === "IMAGE") {
        obj.tint = this.string2hex(value[Object.keys(value)[i]]);
      } else if (
        (Object.keys(value)[i] === "fill" ||
          Object.keys(value)[i] === "colour") &&
        (obj.type === "RECTANGLE" || obj.type === "CIRCLE")
      ) {
        obj.fillColor = this.string2hex(value[Object.keys(value)[i]]);

        if (obj.type === "RECTANGLE") {
          redrawRectangle({ item: obj });
        } else if (obj.type === "CIRCLE") {
          redrawCircle({ item: obj });
        }
      } else if (Object.keys(value)[i] === "position") {
        obj.position = value[Object.keys(value)[i]];
      } else if (Object.keys(value)[i] === "text") {
        obj[Object.keys(value)[i]] = value[Object.keys(value)[i]];
        let style = obj.style;
        if (style) {
          style.fontSize = style.original_size || style.fontSize;
          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.width = parseInt(textMetrics.width);
          obj.height = parseInt(textMetrics.height);
          style.original_size = style.fontSize;
          while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
            style.fontSize -= 1;

            let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
            obj.height = parseInt(textMetrics.height);
          }
          if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
            obj.width = obj.maxWidth;
          }
        }
      } else if (Object.keys(value)[i] === "fontSize") {
        let style = obj.style;
        style.fontSize = value[Object.keys(value)[i]];
        let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);

        obj.width = parseInt(textMetrics.width);
        obj.height = parseInt(textMetrics.height);
        style.original_size = style.fontSize;
        while (obj.maxHeight > 0 && obj.height > obj.maxHeight) {
          style.fontSize -= 1;

          let textMetrics = PIXI.TextMetrics.measureText(obj.text, style);
          obj.height = parseInt(textMetrics.height);
        }

        if (obj.maxWidth > 0 && obj.width > obj.maxWidth) {
          obj.width = obj.maxWidth;
        }
      } else {
        // console.log(obj);
        if (obj.type === "IMAGE") {
          if (obj._texture) {
            obj[Object.keys(value)[i]] = value[Object.keys(value)[i]];
          }
        } else {
          obj[Object.keys(value)[i]] = value[Object.keys(value)[i]];
        }
      }
    }
  }

  async updateDataPreload({ scene, data, new_loader }) {
    try {
      if (
        data.app !== "football" &&
        data.app !== "rugby" &&
        data.app !== "basketball" &&
        data.app !== "gaa" &&
        data.app !== "climbing"
      ) {
        for (let i = 0; i < data.data.length; i++) {
          let item = data.data[i];

          try {
            let obj = item.uuid
              ? scene.getItemByUUID(item.uuid, scene.scene.children)
              : scene.getItemByName(item.name, scene.scene.children);
            if (Array.isArray(item.value)) {
              for (let t = 0; t < obj.children.length; t++) {
                if (item.value[i].value) {
                  await this.preLoadAssets(
                    obj.children[t],
                    item.value[i].value,
                    new_loader
                  );
                }

                for (let x = 0; x < item.value[i].data.length; x++) {
                  let d = item.value[i].data[x];
                  await this.preLoadAssets(
                    scene.getItemByName(d.name, obj.children[t].children),
                    d.value,
                    new_loader
                  );
                }
              }
            } else {
              this.preLoadAssets(obj, item.value, new_loader);
            }
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        data.data.forEach((item) => {
          let objects = scene.getItemsByDataName({
            name: item.name,
            children: scene.scene.children,
            template: data.scene,
            app: data.app,
          });

          objects.forEach((obj) => {
            console.log(item);
            this.preLoadAssets(obj, item.value, new_loader);
          });
        });
      }
    } catch (e) {
      debugger;
      console.log(e);
    }
  }

  async preLoadAssets(obj, value, new_loader) {
    try {
      for (let i = 0; i < Object.keys(value).length; i++) {
        if (Object.keys(value)[i] === "video") {
          let path_id = this.app.player.project?.use_project_folder
            ? this.app.player.project?._id
            : this.app.player.project.user._id;
          let prefix = this.app.player.project?.use_project_folder
            ? "project/"
            : "";
          let video_src =
            window.projectServerURL +
            "assets/videos/" +
            prefix +
            path_id +
            "/" +
            value[Object.keys(value)[i]];
          if (new_loader.resources[video_src] === undefined) {
            new_loader.add(video_src);
          }
        } else if (Object.keys(value)[i] === "image") {
          let user_location =
            value[Object.keys(value)[i]]?.indexOf("${user}") === 0;

          let path_id =
            this.app.player.project?.use_project_folder && !user_location
              ? this.app.player.project?._id
              : this.app.player.project.user._id;
          let prefix =
            this.app.player.project?.use_project_folder && !user_location
              ? "project/"
              : "";

          let resource =
            window.projectServerURL +
            "assets/images/" +
            prefix +
            path_id +
            "/" +
            encodeURIComponent(
              value[Object.keys(value)[i]]?.replace("${user}", "")
            );

          if (new_loader.resources[resource] === undefined) {
            new_loader.add(resource);
          }
        } else if (Object.keys(value)[i] === "neverno_image") {
          let img = value[Object.keys(value)[i]];
          img =
            window.projectServerURL +
            "neverno/image/" +
            encodeURIComponent(value[Object.keys(value)[i]]);

          if (
            new_loader.resources[value[Object.keys(value)[i]]] === undefined
          ) {
            new_loader.add(value[Object.keys(value)[i]], img);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async triggerAnimation(data, new_loader) {
    if (
      (this.app.preview === false || this.app.renderPVW === true) &&
      this.app.player.project &&
      !this.app.player.project.loading
    ) {
      let scenes = [
        this.app.player.project.scenes.find((scene) => {
          return scene.name === data.scene;
        }),
      ];
      if (data.app === "gaa") {
        let gaa_scenes = this.app.player.project.scenes.filter((s) => {
          return (
            s?.gaa?.templates?.findIndex((t) => t.name === data.scene) > -1
          );
        });
        scenes = gaa_scenes || scenes;
      } else if (data.app === "football") {
        let football_scene = this.app.player.project.scenes.filter((s) => {
          return (
            s?.football?.templates?.findIndex((t) => t.name === data.scene) > -1
          );
        });
        scenes = football_scene || scenes;
      } else if (data.app === "rugby") {
        let rugby_scenes = this.app.player.project.scenes.filter((s) => {
          return (
            s?.rugby?.templates?.findIndex((t) => t.name === data.scene) > -1
          );
        });
        scenes = rugby_scenes || scenes;
      } else if (data.app === "basketball") {
        let basketball_scenes = this.app.player.project.scenes.filter((s) => {
          return (
            s?.basketball?.templates?.findIndex((t) => t.name === data.scene) >
            -1
          );
        });
        scenes = basketball_scenes || scenes;
      } else if (data.app === "climbing") {
        let climbing_scenes = this.app.player.project.scenes.filter((s) => {
          return (
            s?.climbing?.templates?.findIndex((t) => t.name === data.scene) > -1
          );
        });
        scenes = climbing_scenes || scenes;
      }

      if (scenes?.length > 0) {
        // let newer = semver.gt(
        //   this.app.player.project.engine_version,
        //   "2023.1.1"
        // );
        // let length = newer ? scenes.length : 1;
        //version not yet implemented
        let length = scenes.length;
        for (let i = 0; i < length; i++) {
          let scene = scenes[i];
          await this.updateDataPreload({ scene, data, new_loader });
          let start = Date.now();
          new_loader.onError.add((err) => {
            console.log("error loading image", err);
          });
          new_loader.load(async () => {
            try {
              let layer_scene = this.app.player.project.scenes.find((s) => {
                return (
                  scene.transition_layer &&
                  scene.transition_layer !== "" &&
                  s.transition_layer === scene.transition_layer &&
                  s.onAir &&
                  s.name !== scene.name
                );
              });

              if (!layer_scene) {
                layer_scene = scene;
              }
              if (
                data.timeline === "IN" &&
                layer_scene.onAir &&
                layer_scene.name === scene.name &&
                scene.timelines.find((tl) => {
                  return tl.name === "UPDATE-IN";
                })
              ) {
                let outTL = layer_scene.timelines.find((tl) => {
                  return tl.name === "UPDATE-OUT";
                });

                let time = 0;
                if (outTL) {
                  outTL.animate_background = false;
                  time = outTL.duration;

                  outTL.currentTime = 0;
                  outTL.play();
                }
                clearTimeout(this.updateFFImageTimout);
                clearTimeout(this.updateTimeout);
                this.updateTimeout = setTimeout(async () => {
                  let timeline = scene.timelines.find((tl) => {
                    return tl.name === "UPDATE-IN";
                  });
                  layer_scene.onAir = true;
                  // await timeline.seek(0);
                  timeline.currentTime = 0;
                  this.updateData({ scene, data, new_loader });
                  outTL.stop();
                  timeline.play();
                }, (1000 / outTL.fps) * time + 2);

                return;
              } else if (
                data.timeline === "IN" &&
                layer_scene.onAir &&
                layer_scene.name !== scene.name
              ) {
                this.updateData({ scene, data: data, new_loader });

                let outTL = layer_scene.timelines.find((tl) => {
                  return tl.name === "OUT";
                });

                let time = 0;
                if (outTL) {
                  layer_scene.onAir = false;
                  time = outTL.duration;
                  // await outTL.seek(0);
                  outTL.currentTime = 0;
                  outTL.animate_background = false;
                  outTL.play();
                }
                this.updateTimeout = setTimeout(async () => {
                  let timeline = scene.timelines.find((tl) => {
                    return tl.name === "IN";
                  });
                  scene.onAir = true;
                  // await timeline.seek(0);
                  timeline.currentTime = 0;
                  timeline.animate_background = false;
                  this.updateData({ scene, data, new_loader });
                  timeline.play();
                }, (1000 / outTL.fps) * time + 2);
              } else {
                this.updateData({ scene, data, new_loader });
                let timeline = scene.timelines.find((tl) => {
                  return tl.name === data.timeline;
                });
                let play = timeline?.playAnimation();
                if (play) {
                  if (data.timeline === "IN") {
                    scene.onAir = true;
                  } else if (data.timeline === "OUT") {
                    scene.timelines
                      .find((tl) => {
                        return tl.name === "IN";
                      })
                      .stop();
                    scene.onAir = false;
                  }

                  timeline.animate_background = true;
                  timeline.currentTime = 0;
                  if (timeline.reverse_in) {
                    scene.timelines
                      .find((tl) => {
                        return tl.name === "IN";
                      })
                      .reverse();
                  } else {
                    timeline.play();
                  }
                }
              }
              console.log("new loader load", Date.now() - start);
            } catch (err) {
              console.error(err);
            }
          });
        }
      }
    }
  }

  async triggerPreview({ scene, data }) {
    try {
      if (this.app.preview && this.app.player.project) {
        this.app.player.project.scenes.map((scene) => {
          scene.timelines.forEach((tl) => {
            tl.stop();
          });
          scene.scene.visible = false;
        });

        let timeline = scene.timelines.find((tl) => {
          return tl.name === data.timeline;
        });

        // await timeline.seek(0);
        timeline.currentTime = 0;
        let play = timeline?.playAnimation();

        if (play) {
          scene.preload();

          await timeline.seek(data.frame);
          // setTimeout(async () => {
          //   timeline.seek(0);
          //   for (let i = 1; i <= data.frame; i++) {
          //     timeline.seek(i);
          //   }
          // }, 50);

          scene.scene.visible = true;
        }
      } else if (this.app.player.project) {
        let scene = this.app.player.project.scenes.find(
          (scene) => scene.name === data.scene
        );
        // scene.preload();
        console.log("---- SCENE " + scene.name + " LOADED ------");
      }
    } catch (err) {
      console.error(err);
    }
  }

  updateClock() {
    try {
      if (this.clockStarted) {
        let totalSeconds = parseInt((Date.now() - this.clockStartTime) / 1000);

        this.seconds = this.pad(totalSeconds % 60);
        this.minutes = this.pad(parseInt(totalSeconds / 60));

        let scene = this.app.player.project.scenes.find(
          (scene) => scene.name === "Clock"
        );
        scene.getItemByName("Game Clock", scene.scene.children).text =
          this.minutes + ":" + this.seconds;
      }
    } catch (e) {}
  }

  pad(val) {
    var valString = val + "";
    if (valString.length < 2) {
      return "0" + valString;
    } else {
      return valString;
    }
  }

  send(data) {
    if (this.ws.readyState === 1) {
      try {
        this.ws.send(JSON.stringify(data));
      } catch (err) {
        console.log(err);
      }
    }
  }

  clearGraphics() {
    if (this.app.player.project) {
      this.app.player.project.scenes.forEach((scene) => {
        scene.timelines.forEach((tl) => {
          tl.stop();
        });
        scene.onAir = false;
        scene.scene.visible = false;
        scene.scene.renderable = false;
        // console.log("cleared", scene.name);
        let timeline = scene.timelines.find((tl) => {
          return tl.name === "OUT";
        });
        if (timeline.reverse_in) {
          scene.timelines
            .find((tl) => {
              return tl.name === "IN";
            })
            .seek(0);
        } else {
          timeline.seek(timeline.duration - 1);
          timeline.restart();
        }
      });
    }
  }

  string2hex(string) {
    if (typeof string === "string" && string[0] === "#") {
      string = string.substr(1);
    }

    return parseInt(string, 16);
  }
}

export default Commander;
