import moment from "moment";

class PrivateClockManager {
  constructor() {
    this.clocks = [];
  }

  getClock({ url }) {
    let index = this.clocks.findIndex((c) => c.url === url);
    if (index > -1) {
      return this.clocks[index];
    } else {
      let new_clock = new Clock({ url });
      this.clocks.push(new_clock);
      return new_clock;
    }
  }
}

class Clock {
  constructor({ url }) {
    this.url = url;
    this.target = new EventTarget();
    this.connect();
  }

  onClockUpdate(callback) {
    this.target.removeEventListener("clock_updated", callback);
    this.target.addEventListener("clock_updated", callback);
  }

  connect() {
    try {
      this.reconnect = true;
      console.log("Clock connecting to " + this.url);
      let timeout;
      this.ws = new WebSocket(this.url);
      this.ws.onerror = (err) => {
        console.log("WS error", err);
        this.ws.close();
      };
      this.ws.onclose = (e) => {
        console.log("Clock on close", e);
        if (this.reconnect) {
          clearInterval(this.keep_alive);
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.connect();
          }, 1000);
        }
      };
      this.ws.onopen = () => {
        console.log("Clock connected to " + this.url);
        clearInterval(this.keep_alive);
        this.keep_alive = setInterval(() => {
          try {
            this.ws.send(
              JSON.stringify({
                keep_alive: Date.now(),
                group: window.id,
              })
            );
          } catch (err) {
            console.error(err);
          }
        }, 20000);
      };

      this.ws.onmessage = (data) => {
        try {
          let obj = JSON.parse(data.data);

          if (obj.keep_alive) {
          } else if (obj.hyperTime?.game?.time?.indexOf(":") > -1) {
            let mins = obj.hyperTime?.game?.time?.split(":")[0];
            let seconds = obj.hyperTime?.game?.time?.split(":")[1];
            let duration = mins * 60000;
            duration += seconds * 1000;

            let game_clock = obj.hyperTime?.game?.time;
            let shot_clock = obj.hyperTime?.shot?.time;

            this.target.dispatchEvent(
              new CustomEvent("clock_updated", {
                detail: {
                  time: duration,
                  game_clock,
                  shot_clock,
                },
              })
            );
          } else {
            let game_clock = obj.hyperTime?.game?.time;
            let shot_clock = obj.hyperTime?.shot?.time;
            this.target.dispatchEvent(
              new CustomEvent("clock_updated", {
                detail: {
                  game_clock,
                  shot_clock,
                },
              })
            );
          }

          //console.log(obj);
        } catch (err) {
          console.error(err);
        }
      };
    } catch (err) {
      console.error(err);
    }
  }
}

export default class ClockManager {
  constructor() {
    throw new Error("Use ClockManager.getInstance()");
  }
  static getInstance(db) {
    if (!ClockManager.instance) {
      ClockManager.instance = new PrivateClockManager(db);
    }
    return ClockManager.instance;
  }
}
